
import { mdiTimerOutline } from '@mdi/js'
export default {
  name: 'ArticlePreviewBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdiTimerOutline,
    }
  },
  computed: {
    article() {
      return {
        title: this.blok.title,
        teaser: this.blok.teaser,
        image: this.blok.image,
      }
    },
    slug() {
      return this.blok.route
    },
    floatingStyle() {
      return { '--img-source': `url(${this.article.image?.filename})` }
    },
  },
}
